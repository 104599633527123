import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const ImgPasse = ({ id, ...props }) => {
  const data = useStaticQuery(graphql`
    query imagesPasse {
      allMysqlPassePartout {
        edges {
          node {
            pa_id
            mysqlImages {
              childImageSharp {
                fixed(height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `)

  let match = null
  match = useMemo(() => data.allMysqlPassePartout.edges.find(({ node }) => id === node.pa_id), [data, id])

  //console.log(id, props, match)
  return <Img fixed={match.node.mysqlImages[0].childImageSharp.fixed} {...props} />
}
ImgPasse.propTypes = {
  id: PropTypes.number,
}
export default ImgPasse
