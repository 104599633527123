import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const ImgBaguette = ({ id, type, ...props }) => {
  // Pas besoin de spécifier des dimensions ici, elles sont redimensionnées en php
  const data = useStaticQuery(graphql`
    query images {
      allMysqlNewProduit {
        edges {
          node {
            ba_id
            mysqlImages {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      allMysqlProductsCoin {
        edges {
          node {
            ba_id
            mysqlImages {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  let match = null
  switch (type) {
    case "coin":
      match = useMemo(() => data.allMysqlProductsCoin.edges.find(({ node }) => id === node.ba_id), [data, id])
      break
    case "longueur":
      match = useMemo(() => data.allMysqlNewProduit.edges.find(({ node }) => id === node.ba_id), [data, id])
      break
  }
  return type == "longueur" ? (
    <Img fluid={match.node.mysqlImages[0].childImageSharp.fluid} {...props} />
  ) : (
    <Img fixed={match.node.mysqlImages[0].childImageSharp.fixed} {...props} />
  )
}
ImgBaguette.defaultProps = { type: "longueur" }
ImgBaguette.propTypes = {
  id: PropTypes.number,
  type: PropTypes.oneOf(["longueur", "coin", "zoom"]),
}
export default ImgBaguette
