import React from "react"
import PropTypes from "prop-types"

import "./all.sass"

const Banner = () => (
  <div className="section banner">
    <div className="container is-fluid">
      <div className="columns has-text-centered">
        <div className="column">
          <div className="is-flex" style={{justifyContent: 'center', alignItems: 'center'}}>
            <svg width="60" height="60" className="mr-5">
              <use xlinkHref="#fabrication" />
            </svg>
            <div>
              <div>Minimum de commande&nbsp;:</div>
              <div className="has-text-weight-bold" style={{ fontSize: "22px" }}>
                375 € HT
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="is-flex" style={{justifyContent: 'center', alignItems: 'center'}}>
            <svg width="60" height="60" className="mr-5">
              <use xlinkHref="#box" />
            </svg>
            <div>
              <div>Port et emballage&nbsp;: 50 € HT</div>
              <div style={{ fontSize: "17px" }} className="has-text-weight-bold">
                Offerts à partir de 750&nbsp;€&nbsp;HT
              </div>
              <small style={{ fontSize: "13px" }}>Possibilité de retrait en magasin</small>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="is-flex" style={{justifyContent: 'center', alignItems: 'center'}}>
            <svg height="60" width="60" className="mr-5">
              <use xlinkHref="#watch" />
            </svg>
            <div>
              <div>
                Délai moyen de traitement
                <br />
                des commandes&nbsp;:
              </div>
              <div style={{ fontSize: "20px" }}>8 jours ouvrés</div>
            </div>
          </div>
        </div>
        <div className="column columns is-vcentered is-centered">
          <a href="https://www.producadre.fr/" style={{ display: "block" }}>
            <svg style={{ height: 60, width: 140, background: "#fff" }}>
              <use xlinkHref="#pfc" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
)

Banner.propTypes = {
  data: PropTypes.object,
}

export default Banner
