import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"

import NavBar from "./nav-bar"
import Banner from "./banner"
import { ReactComponent as Sprite } from "../images/sprite.svg"
import { ErrorBoundary } from "react-error-boundary"
import { SlackOAuthClient } from "messaging-api-slack"

import "./all.sass"
import { removeAllProducts, resetPageProduct } from "../actions"

class Layout extends React.Component {
  constructor(props) {
    super(props)
  }
  myErrorHandler = (error, componentStack) => {
    // n'envoie les alertes que si on est sur le site, même en prod, si l'url ne match pas inutile d'envoyer
    if (process.env.NODE_ENV == "production" && typeof window !== "undefined" && window.location.href.includes("france-cadre.fr")) {
      const client = new SlackOAuthClient({
        accessToken: "xoxp-12970109927-12994999092-596958357282-d0b1be921f8a7171b658913cd5e2b9ef",
      })
      let url = typeof window !== "undefined" ? window.location.href : ""
      client
        .callMethod("chat.postMessage", {
          channel: "alerts-cadres",

          blocks: JSON.stringify([
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: "France-Cadre sur la page " + url + " :  ",
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: "```" + error + "```",
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: "Détail : ",
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: "```" + componentStack + "```",
              },
            },
          ]),

          as_user: false,
          username: "France-cadres",
        })
        .then(() => {
          this.props.dispatch(removeAllProducts())
          this.props.dispatch(resetPageProduct())
          //window.location.href = "https://www.france-cadre.fr";
        })
    }

    console.error("Une erreur à été detectée, redirection !")
  }

  render() {
    return (
      <>
        <ErrorBoundary onError={this.myErrorHandler}>
          <header className="header">
            <NavBar fixed={!this.props.banner} location={this.props.location} />
          </header>
          <main>
            <div className="is-hidden">
              <Sprite />
            </div>
            {this.props.banner ? <Banner /> : null}
            <div className="container is-fluid">{this.props.children}</div>
          </main>
          <footer className="footer">
            <div className="content has-text-centered">
              <Link to="/mentions-legales">Mentions légales</Link> - <Link to="/conditions-vente">Conditions de vente</Link> -{" "}
              <Link to="/donnees-personnelles">Données personnelles</Link> - <Link to="/nous-contacter">Nous contacter</Link>
            </div>
          </footer>
        </ErrorBoundary>
      </>
    )
  }
}

Layout.defaultProps = {
  banner: true,
  location: { pathname: "/" },
}

Layout.propTypes = {
  banner: PropTypes.bool,
  dispatch: PropTypes.func,
  children: PropTypes.node,
  location: PropTypes.object,
}

export default Layout
