import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ImgBaguette from "./imgBaguette"

export const PrevisuCoinCadre = props => {
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(false)
  useEffect(() => {
    if (props.baguetteInterieure !== null) setLoading2(true)
  }, [])
  useEffect(() => setLoading(true), [props.idProduct])
  useEffect(() => {
    if (props.baguetteInterieure !== null) setLoading2(true)
  }, [props.baguetteInterieure])

  const largeurPx = props.width
  const hauteurPx = props.height
  const baguetteHauteurCm = parseFloat(props.ba_largeur)

  const largeurCadre = parseFloat(18)
  const hauteurCadre = parseFloat(13)

  let fullWidthCm = largeurCadre
  let fullHeightCm = hauteurCadre
  let baguetteHauteurPx
  let cadreLargeurPx
  let cadreHauteurPx

  if (fullWidthCm > fullHeightCm) {
    baguetteHauteurPx = (baguetteHauteurCm * largeurPx) / fullWidthCm

    cadreLargeurPx = largeurPx
    cadreHauteurPx = (fullHeightCm * largeurPx) / fullWidthCm
  } else {
    baguetteHauteurPx = (baguetteHauteurCm * hauteurPx) / fullHeightCm

    cadreLargeurPx = (fullWidthCm * hauteurPx) / fullHeightCm
    cadreHauteurPx = hauteurPx
  }

  //Largeur des petits carrés permettant de dessiner les traits dans les coins
  const largeurCarres = Math.sqrt(2 * (baguetteHauteurPx * baguetteHauteurPx))

  const styleCadre = {
    position: "relative",
    width: cadreLargeurPx,
    height: cadreHauteurPx,
    overflow: "hidden",
    backgroundColor: "transparent",
    filter: "drop-shadow(2px 2px 4px #555)",
  }

  // DIV IMAGES :
  const styleDivHaut = {
    position: "absolute",
    width: cadreLargeurPx,
    left: 0,
    height: baguetteHauteurPx,
    overflow: "hidden",
    zIndex: 2,
    clipPath:
      "polygon(0 0, " + cadreLargeurPx + "px 0, " + cadreLargeurPx + "px " + baguetteHauteurPx + "px, " + baguetteHauteurPx + "px " + baguetteHauteurPx + "px)",
  }

  const styleDivGauche = {
    position: "absolute",
    width: baguetteHauteurPx,
    height: largeurPx,
    left: 0,
    top: 0,
    overflow: "hidden",
    zIndex: 2,
    clipPath:
      "polygon(" + baguetteHauteurPx + "px " + baguetteHauteurPx + "px, 0 0, 0 " + cadreHauteurPx + "px, " + baguetteHauteurPx + "px " + cadreHauteurPx + "px)",
  }

  // IMAGES :
  const styleImageHaut = {
    position: "absolute",
    width: largeurPx,
    left: 0,
    height: baguetteHauteurPx + "px",
    boxShadow: "1px 1px 5px rgb(85, 85, 85, 0.5)",
  }

  const styleImageGauche = {
    position: "absolute",
    top: largeurPx,
    left: 0,
    width: largeurPx,
    transformOrigin: "top left",
    transform: "rotate(-90deg)",
    height: baguetteHauteurPx + "px",
    boxShadow: "1px 1px 5px rgb(85, 85, 85, 0.5)",
  }

  let baguetteHauteurPx2, styleDivHaut2, styleDivGauche2, styleImageHaut2, styleImageGauche2
  if (props.baguetteInterieure !== null) {
    if (fullWidthCm > fullHeightCm) {
      baguetteHauteurPx2 = (props.baguetteInterieure.ba_largeur * largeurPx) / fullWidthCm
    } else {
      baguetteHauteurPx2 = (props.baguetteInterieure.ba_largeur * hauteurPx) / fullHeightCm
    }
    const recouvrement = 4
    let cadreLargeurPx2 = cadreLargeurPx
    let cadreHauteurPx2 = cadreHauteurPx
    //Conteneur baguette haut
    styleDivHaut2 = {
      width: cadreLargeurPx2,
      height: baguetteHauteurPx2,
      top: baguetteHauteurPx - recouvrement + 1,
      left: baguetteHauteurPx - recouvrement,
      position: "absolute",
    }

    //Conteneur baguette gauche
    styleDivGauche2 = {
      position: "absolute",
      left: baguetteHauteurPx - recouvrement,
      top: baguetteHauteurPx - recouvrement,
      width: baguetteHauteurPx2,
      height: cadreHauteurPx2,
      clipPath:
        "polygon(" +
        baguetteHauteurPx2 +
        "px " +
        baguetteHauteurPx2 +
        "px, 0 0, 0 " +
        cadreHauteurPx2 +
        "px, " +
        baguetteHauteurPx2 +
        "px " +
        cadreHauteurPx2 +
        "px)",
    }

    //Baguette haut
    styleImageHaut2 = {
      position: "absolute",
      left: 0,
      width: cadreLargeurPx2,
      height: baguetteHauteurPx2,
    }

    //Baguette gauche
    styleImageGauche2 = {
      position: "relative",
      width: cadreHauteurPx2,
      height: baguetteHauteurPx2,
      transform: "rotate(-90deg)",
      transformOrigin: "center",
      top: cadreHauteurPx2 / 2 - baguetteHauteurPx2 / 2,
      left: 0 - cadreHauteurPx2 / 2 + baguetteHauteurPx2 / 2,
    }
  }
  // CARRES :
  const styleCarreHautGauche = {
    position: "absolute",
    width: largeurCarres,
    height: largeurCarres,
    top: "0px",
    borderStyle: "solid none none none",
    borderWidth: "1px",
    borderColor: "#939191",
    opacity: "0.2",
    transformOrigin: "top left",
    transform: "rotate(45deg)",
  }

  const styleLoader = {
    background: "white",
    zIndex: 2,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    textAlign: "center",
    paddingTop: cadreHauteurPx / 2 - 25,
  }
  return (
    <>
      <div style={styleCadre}>
        {loading || (props.baguetteInterieure !== null && loading2) ? (
          <div style={styleLoader}>
            <div className="spinner"></div>
          </div>
        ) : (
          ""
        )}

        <div style={styleDivHaut}>
          <ImgBaguette id={props.idProduct} type="coin" onLoad={() => setLoading(false)} style={styleImageHaut} alt={"Baguette1"} />
        </div>
        <div style={styleDivGauche}>
          <ImgBaguette id={props.idProduct} style={styleImageGauche} type="coin" alt={"Baguette1"} />
        </div>
        {props.baguetteInterieure !== null ? (
          <>
            <div style={styleDivHaut2}>
              <ImgBaguette id={props.baguetteInterieure.ba_id} style={styleImageHaut2} type="coin" onLoad={() => setLoading2(false)} alt={"Baguette1"} />
            </div>
            <div style={styleDivGauche2}>
              <ImgBaguette id={props.baguetteInterieure.ba_id} style={styleImageGauche2} type="coin" alt={"Baguette1"} />
            </div>
          </>
        ) : null}

        <div style={styleCarreHautGauche}></div>
      </div>
    </>
  )
}

PrevisuCoinCadre.defaultProps = {
  baguetteInterieure: null,
  width: 188,
  height: 120,
}
PrevisuCoinCadre.propTypes = {
  width: PropTypes.number,
  idProduct: PropTypes.number,
  height: PropTypes.number,
  ba_largeur: PropTypes.number,
  baguetteInterieure: PropTypes.object,
}

export default PrevisuCoinCadre
