import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"

import { removeProduct } from "../actions/index"
import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"
import PrevisuCoinCadre from "./../components/previsuCoinCadre"
import ImgBaguette from "./imgBaguette"
import ImgPasse from "./imgPasse"
import { useDispatch, useSelector } from "react-redux"
export const PopupPanier = props => {
  const data = useStaticQuery(graphql`
    query productForImage {
      allMysqlNewProduit(filter: { isDoublo: { eq: 0 } }) {
        edges {
          node {
            id
            ba_id
            ba_descriptif
            ba_photo_longueur
            ba_croquis
            bas_reference
            co_id
            co_libelle
            fa_id
            fa_libelle

            ba_prix_fourniture
            ba_prix
            ba_prix_majoration
            ba_largeur
            ba_chutes
          }
        }
      }

      allMysqlPassePartout {
        edges {
          node {
            pa_id
            pat_libelle
            pat_id
            pa_libelle
            pa_description
            pa_reference
          }
        }
      }

      allMysqlAccessoires {
        edges {
          node {
            pa_id
            pat_libelle
            pat_id
            pa_libelle
            pa_description
            pa_reference

            mysqlImages {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const panier = useSelector(s => s.cart)
  const identifiants = useSelector(s => s.identifiants).identifiants
  const total = panier.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0)
  const dispatch = useDispatch()
  const handleRemove = id_product => {
    dispatch(
      removeProduct({
        _id: id_product,
      })
    )
    if (props.removeProduct) {
      props.removeProduct(total)
    }
  }

  return (
    <>
      {parseInt(panier.products.reduce((acc, currrent) => acc + parseFloat(currrent.price) * currrent.qty, 0).toFixed(0)) > 450 ? (
        <div className="has-text-centered">
          <Link className="button" to="/panier">
            Voir le panier
          </Link>
          <p className="has-text-centered">Retrouver le détail de votre panier sur la page suivante</p>
        </div>
      ) : (
        ""
      )}
      {panier && panier.products
        ? panier.products.map((produit, index) => (
            <div className="popupPanierBorder" key={index}>
              <div style={{ textAlign: "end", marginTop: "1rem" }}>
                <button className="delete" onClick={() => handleRemove(produit._id, parseFloat(produit.price) * produit.qty)}></button>
              </div>
              <div style={{ marginTop: "1rem" }}>
                {produit.type == "passe_partout" ? (
                  <ImgPasse id={produit.product.pa_id} />
                ) : produit.type == "accessoire" ? (
                  data.allMysqlAccessoires.edges.filter(u => u.node.pa_id == produit.id_product).length > 0 ? (
                    <Img fluid={data.allMysqlAccessoires.edges.filter(u => u.node.pa_id == produit.id_product)[0].node.mysqlImages[0].childImageSharp.fluid} />
                  ) : (
                    <p style={{ color: "red" }}>Erreur supprimer le produit !</p>
                  )
                ) : data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product).length > 0 ? (
                  produit.type == "echantillon" ? (
                    <PrevisuCoinCadre
                      width={200}
                      height={80}
                      ba_largeur={
                        data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product)[0].node.ba_largeur > 39
                          ? 10
                          : data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product)[0].node.ba_largeur
                      }
                      idProduct={produit.ba_id}
                    />
                  ) : (
                    <ImgBaguette style={{ height: "30px", boxShadow: "1px 1px 2px #a0a0a0" }} id={produit.ba_id} />
                  )
                ) : (
                  <p style={{ color: "red" }}>Erreur supprimer le produit !</p>
                )}
                <p>
                  <strong>
                    {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                      parseFloat(produit.price / 1.2) * (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1)
                    )}
                  </strong>{" "}
                  X {produit.qty}
                </p>
              </div>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: produit.description,
                  }}
                ></p>
              </div>
            </div>
          ))
        : ""}

      {parseInt(panier.products.reduce((acc, currrent) => acc + parseFloat(currrent.price / 1.2) * currrent.qty, 0).toFixed(0)) !== 0 ? (
        <div>
          <p>
            Total :{" "}
            <strong>
              {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                panier.products.reduce((acc, currrent) => acc + parseFloat(currrent.price / 1.2) * currrent.qty, 0) *
                  (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1)
              )}{" "}
              HT
            </strong>
          </p>

          {parseInt(panier.products.reduce((acc, currrent) => acc + parseFloat(currrent.price) * currrent.qty, 0).toFixed(0)) > 450 ? (
            <div className="has-text-right">
              <Link className="button is-large" to="/panier">
                Voir le panier
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <p>Votre panier est vide</p>
      )}
    </>
  )
}

PopupPanier.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
  removeProduct: PropTypes.func,
  cart: PropTypes.object,
}

export default PopupPanier
