import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { logoutR, removeAllProducts } from "./../actions/index"

import PopupPanier from "../components/popupPanier"
import { useDispatch, useSelector } from "react-redux"

export const Navbar = props => {
  const [active, setActive] = useState(false)
  const identifiants = useSelector(s => s.identifiants).identifiants
  const panier = useSelector(s => s.cart)
  const dispatch = useDispatch()

  const handleLogout = evt => {
    evt.preventDefault()
    // vider le panier si on se déconnecte
    dispatch(removeAllProducts([]))
    dispatch(
      logoutR({
        identifiant: "deconnecte",
      })
    )
  }

  return (
    <nav className={`navbar is-dark${props.fixed ? " is-fixed-top" : ""}`} role="navigation" aria-label="main navigation">
      <div className="container is-fluid">
        <div className="navbar-brand">
          {identifiants !== null && identifiants.mode ? (
            <div className="navbar-company">
              {identifiants.company} - {identifiants.lastname} {identifiants.firstname}
            </div>
          ) : (
            <Link className="navbar-item logo" to="/">
              <svg height="55" width="125">
                <use xlinkHref="#logo_france-cadre" />
              </svg>
            </Link>
          )}
          <div
            className={`navbar-burger burger`}
            aria-label="menu"
            aria-expanded="false"
            data-target="mainNavbar"
            onClick={() => setActive(!active)}
            onKeyPress={() => {}}
            tabIndex={0}
            role="button"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </div>

        <div className={`navbar-menu ${active ? "is-active" : ""}`}>
          <div className="navbar-start">
            <Link className="navbar-item" to="/cadres">
              Cadres
            </Link>
            <Link className="navbar-item" to="/passe-partout">
              Passe-partout
            </Link>
            <Link className="navbar-item" to="/accessoires">
              Accessoires
            </Link>
            {panier.products.length > 0 ? (
              <Link className="navbar-item is-hidden-desktop" to="/panier" aria-haspopup="true" aria-controls="dropdown-menu4">
                Panier
              </Link>
            ) : (
              ""
            )}
            <Link className="navbar-item" to="/nous-contacter">
              Contact
            </Link>
          </div>

          <div className="navbar-end">
            {identifiants !== null && !identifiants.mode && (
              <div className="navbar-item ">
                <div className="telephone">
                  <div className="columns">
                    <div className="column">
                      <span style={{ fontSize: "0.9em" }}>Une question, un conseil ?</span>
                      <div style={{ fontSize: "0.8rem", marginTop: 3 }}>
                        Nous sommes à votre disposition au <b>03 89 06 00 93</b>
                      </div>
                      <div style={{ marginTop: 6, marginBottom: 2, lineHeight: 0, fontSize: "0.4em" }}>du lundi au vendredi 9h-18h et samedi 9h-12h</div>
                    </div>
                    <div className="column" style={{ flexGrow: 0 }}>
                      <svg>
                        <use xlinkHref="#phone" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="dropdown is-hoverable navbar-item">
              <div className="dropdown-trigger is-hidden-touch">
                {panier.products.reduce((acc, currrent) => acc + parseInt(currrent.qty), 0) == 0 ? (
                  <>
                    <div className="navbar-item" style={{ color: "#fff" }}>
                      <svg width="45" height="40">
                        <use xlinkHref="#panier" />
                      </svg>
                      Panier
                    </div>
                  </>
                ) : (
                  <Link className="navbar-item" to="/panier" aria-haspopup="true" aria-controls="dropdown-menu4">
                    {identifiants !== null && !identifiants.mode && (
                      <span className="spanPanier">{panier.products.reduce((acc, currrent) => acc + parseInt(currrent.qty), 0)}</span>
                    )}
                    <svg width="45" height="40">
                      <use xlinkHref="#panier" />
                    </svg>
                    Panier
                  </Link>
                )}
              </div>
              {identifiants !== null && !identifiants.mode && (
                <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                  <div className="dropdown-content">
                    <div className="dropdown-item">
                      <PopupPanier />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {identifiants !== null ? (
              <>
                <Link className="navbar-item" to="/apps/mon-compte/">
                  Mon compte
                </Link>
                <a href="/" className="navbar-item" onClick={handleLogout}>
                  Déconnexion
                </a>
              </>
            ) : (
              <Link to={`/connexion?redirect=${props.location.pathname}`} className="navbar-item">
                Connexion
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  dispatch: PropTypes.func,
  fixed: PropTypes.bool,
  identifiants: PropTypes.object,
  cart: PropTypes.object,
  location: PropTypes.object,
}

export default Navbar
